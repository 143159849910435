import React from 'react';
import styled from 'styled-components';
import DummyEventSVG from './dummy-event.svg';

const Icon = styled.svg`
  height: 100%;
  width: 100%;
`;

const DummyEventContainer = styled.div`
  height: calc(100% - 36px);

  @media (max-width: 575px) {
    height: calc(100% - 18px);
  }
`;

const DummyEvent = props => (
  <DummyEventContainer>
    {/* <Icon image={DummyEventSVG}/> */}
    <Icon
      id="Layer_1"
      dataName="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 408 498"
      fill={props.fill}
      preserveAspectRatio="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M403.575 2H3.171L203.25 247.324L403.575 2ZM405.5 2.66637L204.484 248.837L405.5 495.31V2.66637ZM202.015 248.836L2.00011 3.59099V493.781L202.015 248.836ZM2.93466 495.66L203.249 250.349L403.317 495.66H2.93466ZM0 496.23L0.000110347 497.66H2.00011H405.5H407.5V495.66V2V0H405.5H2.00011H0.000110347V1.13872V2V495.66L0 496.23Z"
      />
    </Icon>
  </DummyEventContainer>
);

export default DummyEvent;
