import React from 'react';
import styled from 'styled-components';

const SubtitleHeading = styled.h3`
  font-size: 26px;

  color: ${props => props.color};

  text-align: ${props => props.align || 'center'};
  text-transform: ${props => props.case || 'uppercase'};

  margin: ${props => props.margin || '0 0 75px 0'};

  @media (max-width: 767px) {
    font-size: 20px;
    margin: ${props => props.mobileMargin || '40px 9px'};
  }
`;

const Subtitle = props => (
  <SubtitleHeading
    margin={props.margin}
    mobileMargin={props.mobileMargin}
    case={props.case}
    color={props.color}
    align={props.align}
  >
    {props.text}
  </SubtitleHeading>
);

export default Subtitle;
