import React from 'react';
import styled from 'styled-components';

const TitleHeading = styled.h1`
  font-size: 45px;

  text-align: center;
  text-transform: ${props => props.case || 'uppercase'};

  margin: ${props => props.margin || '75px 0 35px 0'};

  @media (max-width: 767px) {
    margin: 0;
    font-size: 36px;
  }

  @media (max-width: 576px) {
    font-size: 24px;
  }
`;

const Title = props => (
  <TitleHeading margin={props.margin} case={props.case}>
    {props.text}
  </TitleHeading>
);

export default Title;
