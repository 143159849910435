import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';

const TextContainer = styled.div`
  font-size: ${props => props.fontSize || '20px'};

  & > p:first-of-type {
    margin-top: 0;
  }

  margin: ${props => props.singleTextboxMargin};

  &:nth-child(2) {
    margin: ${props => props.bottomMargin || '0 0 75px 0'};

    color: ${props => props.color};
  }

  & a {
    color: inherit;
  }

  @media (max-width: 767px) {
    margin: ${props => props.mobileBottomMargin};

    &:nth-child(2) {
      margin: 0 9px 40px 9px;
    }
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }
`;

const Textbox = props => (
  <Col col={12} md={10} lg={10}>
    <Row justifyContent="center">
      <Col col={12} sm={9} md={9} lg={7} xl={6}>
        <TextContainer
          fontSize={props.fontSize}
          bottomMargin={props.bottomMargin}
          mobileBottomMargin={props.mobileBottomMargin}
          singleTextboxMargin={props.singleTextboxMargin}
          dangerouslySetInnerHTML={{
            __html: props.text,
          }}
        />

        {props.moreInformation && (
          <TextContainer
            color={props.moreInformationTextColor}
            bottomMargin={props.bottomMargin}
            mobileBottomMargin={props.mobileBottomMargin}
          >
            <br />
            {props.moreInformation}
          </TextContainer>
        )}
      </Col>
    </Row>
  </Col>
);

export default Textbox;
